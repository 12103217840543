import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const FlightSeatIndicator = ({ sectionWithSeat }) => (
  <div className="col-grid seat-info no-padding">
    <div className="seat-info__cabin">
      <I18nText id="flight_seat_map.legends" />{' '}
      {sectionWithSeat && I18n.t(sectionWithSeat.cabinClass, { scope: 'cabin_classes_seatmap' })}
    </div>
    <p className="seat-info__availability">
      <span className="seat-info__availability-status seat-info__availability-status--available" />
      <I18nText id="flight_seat_map.avaibility_info.available" />
    </p>
    <p className="seat-info__availability">
      <span className="seat-info__availability-status seat-info__availability-status--selected" />
      <I18nText id="flight_seat_map.avaibility_info.selected" />
    </p>
    <p className="seat-info__availability">
      <span className="seat-info__availability-status seat-info__availability-status--free" />
      <I18nText id="flight_seat_map.avaibility_info.free" />
    </p>
    <p className="seat-info__availability">
      <span className="seat-info__availability-status seat-info__availability-status--occupied" />
      <I18nText id="flight_seat_map.avaibility_info.occupied" />
    </p>
    <p className="seat-info__availability">
      <span className="seat-info__availability-status seat-info__availability-status--absent" />
      <I18nText id="flight_seat_map.avaibility_info.absent" />
    </p>
  </div>
);

FlightSeatIndicator.propTypes = {
  sectionWithSeat: PropTypes.shape({
    cabinClass: PropTypes.string.isRequired,
  }).isRequired,
};

export default FlightSeatIndicator;
