import { FLIGHT_SERVICES_FETCHED } from '../../actions/flight';

export function mapSeatMapSection(section) {
  const rows = [];
  const columns = new Set();
  const aisleColumns = new Set();

  Object.keys(section.rows).forEach(number => {
    const row = section.rows[number];
    Object.entries(row.seats).forEach(([columnKey, column]) => {
      columns.add(columnKey);
      if (column.aisle) {
        aisleColumns.add(columnKey);
      }
    });
  });

  Object.keys(section.rows).forEach(number => {
    const row = section.rows[number];
    const seats = [];
    columns.forEach(columnKey => {
      const seat = row.seats[columnKey];
      let seatAttributes = {};
      if (seat) {
        seatAttributes = {
          id: seat.id,
          name: columnKey,
          aisle: seat.aisle,
          chargeable: seat.chargeable,
          reclineRestricted: seat.reclineRestricted,
          absent: !seat.seatExistence,
          occupied: seat.occupied,
          infantRestricted: seat.infantRestricted,
          childRestricted: seat.childRestricted,
          window: seat.window,
          pricing: {
            amount: seat.pricingInfo.seatReservationPrice,
            currency: seat.pricingInfo.currencyCode,
          },
        };
      } else {
        seatAttributes = {
          aisle: aisleColumns.has(columnKey),
          absent: true,
        };
      }
      seats.push(seatAttributes);
    });
    rows.push({ exit: row.exit, wing: row.wing, number, seats });
  });
  return {
    rows,
    cabinClass: section.class,
    columns: Array.from(columns),
    aisleColumns: Array.from(aisleColumns),
  };
}

export default function servicesByResultId(state = {}, action) {
  const newState = Object.assign({}, state);
  const resultId = action.id;

  switch (action.type) {
    case FLIGHT_SERVICES_FETCHED:
      newState[resultId] = action.services;
      newState[resultId].seatMaps = action.services.seatMaps.map(seatMap => ({
        ...seatMap,
        sections: seatMap.sections.map(section => mapSeatMapSection(section)),
      }));
      newState[resultId].formOfPayment = action.formOfPayment;
      newState[resultId].paymentCharge = action.paymentCharge;
      newState[resultId].mealRequests = action.mealRequests;
      newState[resultId].identificationDocuments = action.identificationDocuments;
      newState[resultId].assistanceRequests = action.assistanceRequests;
      return newState;

    default:
      return newState;
  }
}
