import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '@wtag/rcl-popover';
import { Spinner } from '@wtag/react-comp-lib';
import SeatDetails from './SeatDetailsContainer';

class SeatMapSeat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      isLoading: false,
      travelerIndex: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookedFor !== prevProps.bookedFor) {
      this.stopLoader();
    }
  }

  setTravelerIndex = value => {
    if (this.props.bookedFor !== value) {
      this.setState({ isPopoverOpen: false, isLoading: true, travelerIndex: value }, () => {
        this.props.selectSeat(this.state.travelerIndex, this.context);
      });
    } else {
      this.hidePopover();
    }
  };

  removeTravelerIndex = removeSeatCallback => {
    this.setState({ isPopoverOpen: false, isLoading: true, travelerIndex: null }, () => {
      removeSeatCallback();
    });
  };

  stopLoader = () => {
    this.setState({ isLoading: false });
  };

  hidePopover = () => {
    this.setState({ isPopoverOpen: false });
  };

  togglePopoverDisplay = () => {
    this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
  };

  absentOrNullPrice = () => this.props.absent || !this.props.pricing.amount;

  isFree = () =>
    !this.absentOrNullPrice() &&
    !this.props.chargeable &&
    !this.props.occupied &&
    this.props.pricing &&
    Number(this.props.pricing.amount) === 0;

  render() {
    const linkText = this.props.booked ? `T${this.props.bookedFor + 1}` : '';
    const classes = classNames({
      seat: true,
      chargeable: this.props.chargeable,
      reclineRestricted: this.props.reclineRestricted,
      free: this.isFree(),
      occupied: this.props.occupied,
      absent: this.absentOrNullPrice(),
      infantRestricted: this.props.infantRestricted,
      childRestricted: this.props.childRestricted,
      window: this.props.window,
      active: this.state.isPopoverOpen,
      booked: this.props.booked && !this.state.isLoading,
      updating: this.props.isUpdating,
    });

    return (
      <div
        className={classNames('popover-container', {
          aisle: this.props.aisle,
        })}
      >
        <Popover
          isVisible={this.state.isPopoverOpen}
          direction="top-left"
          size="medium"
          content={
            !this.absentOrNullPrice() ? (
              <div className="flights">
                <SeatDetails
                  {...this.props}
                  free={this.isFree()}
                  setTravelerIndex={this.setTravelerIndex}
                  removeTravelerIndex={this.removeTravelerIndex}
                />
              </div>
            ) : (
              <div className="flights absent-seat">
                {I18n.t('components.ibe.seat_map.absent_seat_message')}
              </div>
            )
          }
          onOutsideClick={this.hidePopover}
        >
          <div
            className={classNames(classes, 'popover-container__placeholder')}
            role="button"
            tabIndex={0}
            onClick={this.togglePopoverDisplay}
            onKeyDown={this.togglePopoverDisplay}
          >
            {this.state.isLoading ? (
              <Spinner size="tiny" bgColor="neutral" />
            ) : (
              <span className="popover-container__placeholder--text">{linkText}</span>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

SeatMapSeat.defaultProps = {
  bookedFor: null,
  exit: false,
  aisle: false,
  chargeable: false,
  reclineRestricted: false,
  absent: true,
  occupied: false,
  infantRestricted: false,
  childRestricted: false,
  window: false,
  booked: false,
  mainItemId: null,
  pricing: {
    amount: null,
    currency: null,
  },
};

SeatMapSeat.propTypes = {
  row: PropTypes.string.isRequired,
  exit: PropTypes.bool,
  aisle: PropTypes.bool,
  chargeable: PropTypes.bool,
  reclineRestricted: PropTypes.bool,
  absent: PropTypes.bool,
  occupied: PropTypes.oneOf([true, false]),
  infantRestricted: PropTypes.bool,
  childRestricted: PropTypes.bool,
  window: PropTypes.bool,
  booked: PropTypes.bool,
  bookedFor: PropTypes.number,
  mainItemId: PropTypes.number,
  flight: PropTypes.string.isRequired,
  pricing: PropTypes.shape(),
  selectSeat: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

SeatMapSeat.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
};

export default SeatMapSeat;
