import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import Tooltip from '@wtag/rcl-tooltip';
import Alert from 'sharedWebpack/Alert';
import SingleTravelerInformations from './SingleTravelerInformations';
import SelectTravelers from './SelectTravelersContainer';
import CheckoutProgress from '../components/CheckoutProgress';
import RequestApproval from './RequestApproval';
import './styles.scss';

class TravelerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTravelerIndex: 0,
      proceedToPaymentButtonClicked: false,
      approvalButtonDisabled: false,
      customerRequirersApproval: false,
    };
  }

  componentDidMount() {
    const {
      formValues,
      fields: { travelers },
      fetchCurrentCustomer,
      fetchCartPeople,
      cartId,
      customerSelected,
      totalTravelerCount,
      initializeForm,
    } = this.props;

    const firstTraveler = travelers[0];
    const { driverAge } = formValues.travelers[0];

    if (driverAge) {
      firstTraveler.driverAge.onChange(driverAge);
    }

    if (firstTraveler.guestUser && !firstTraveler.guestUser.value) {
      fetchCurrentCustomer(this.context.callbackParams).then(customer => {
        const mappedCustomer = customer;
        if (this.context.laymanMode && mappedCustomer && !mappedCustomer.email) {
          mappedCustomer.email = this.context.travelArrangerDefaultEmail;
        }

        fetchCartPeople(cartId, this.context.callbackParams).then(cartTravelers => {
          if (cartTravelers.data.length > 0) {
            return this.mapAndSelectExistingCartTravelers(cartTravelers, mappedCustomer);
          }
          return customerSelected(0, mappedCustomer);
        });
      });
    }
    if (totalTravelerCount !== travelers.length) {
      initializeForm(formValues);
    }
  }

  setActiveTraveler = index => {
    this.setState({ activeTravelerIndex: index });
  };

  sendApprovalRequest = (optionalApproverIds = []) => {
    this.setState({ approvalButtonDisabled: true });
    this.props.saveApprovalRequest(this.context.callbackParams, optionalApproverIds);
  };

  mapAndSelectExistingCartTravelers = (cartTravelers, currentCustomer) => {
    cartTravelers.data.map((traveler, index) => {
      let travelerData = {
        ...traveler,
        address: traveler.residenceAddress,
        meal: traveler.mealRequests,
      };
      if (index === 0 && !traveler.guestUser && currentCustomer) {
        // InCase cart was created for another customer in Agent
        // then opened on Traveler app for different logged in customer
        travelerData = {
          ...currentCustomer,
          meal: traveler.mealRequests,
          assistanceRequests: traveler.assistanceRequests,
        };
      }
      return this.props.customerSelected(index, travelerData);
    });
  };

  fieldsHasErrors = fields =>
    Object.values(fields).some(field => {
      if (typeof field.invalid !== 'undefined') return field.invalid;
      return Object.values(field).some(nestedField => nestedField.invalid);
    });

  haveError = () =>
    Object.values(this.props.fields.travelers).some(field => this.fieldsHasErrors(field));

  fieldsDataChanged = field => {
    if (field.dirty !== undefined && field.dirty === true) return true;

    if (field.dirty === undefined && field !== null) {
      return Object.values(field).some(nestedValue => this.fieldsDataChanged(nestedValue.dirty));
    }
    return false;
  };

  // We are checking whether the first traveler was changed or any of it's data was changed
  isCartOwnerDataChanged = () =>
    this.props.fields.travelers[0].guestUser.value ||
    Object.values(this.props.fields.travelers[0]).some(value => this.fieldsDataChanged(value));

  travelArrangerWithReadPermission = () =>
    this.context.travelArranger &&
    this.context.travelArranger.customerInformationVisible &&
    !this.context.travelArranger.customerInformationEditable;

  // For Travel arranger with read permission, if the traveller was not changed and
  // if any of the data was changed we will call a different API. We are detecting that case here.
  // For context please see https://github.com/wtag/agent/pull/5297#discussion_r1471803193
  readOnlyTravelArrangerWithUnchangedData = () =>
    this.travelArrangerWithReadPermission() && !this.isCartOwnerDataChanged();

  phoneNumberValidate = () => {
    this.props.asyncPhoneNumberValidate(this.props.fields);
  };

  travelerEmailValidate = () => {
    if (this.props.laymanMode) {
      this.props.asyncTravelerEmailValidate(this.props.fields);
    }
  };

  resetTraveler = index => {
    this.setState({
      customerRequirersApproval: false,
    });
    this.props.resetTraveler(index);
  };

  handleSubmit = () =>
    this.props.saveTravelers(
      this.props.formValues,
      this.context.callbackParams,
      this.readOnlyTravelArrangerWithUnchangedData(),
    );

  checkOwnerRequiresApproval = () => {
    this.props.fetchCart(this.props.cartId, this.context.callbackParams).then(data => {
      this.props.updateTravelerSavingStatus(false);
      if (data.requiresApproval) {
        this.setState({
          customerRequirersApproval: data.requiresApproval,
          proceedToPaymentButtonClicked: false,
        });
      } else {
        this.props.proceedToPayment();
      }
    });
  };

  saveTravelersOnClick = () => {
    this.setState({ proceedToPaymentButtonClicked: true });
    this.props.updateTravelerSavingStatus(true);
    this.props.resetTravelersError();
    this.props.updateProceedToPaymentPageView(true);
    this.handleSubmit().then(() => {
      if (this.props.travelersError.errorList.length > 0) {
        this.setState({ proceedToPaymentButtonClicked: false });
        this.props.updateProceedToPaymentPageView(false);
      }
      this.checkOwnerRequiresApproval();
    });
  };

  hintsForStepperButton = () => {
    if (!this.props.bookingPossible) {
      return <I18nText id="traveler_details.return_to_cart" returnStringOnly={true} />;
    }

    return null;
  };

  approvalRequired = () => this.props.forceApprovalRequest && this.state.customerRequirersApproval;

  travellerSelected = () =>
    this.props.selectedCustomers.length > 0 && this.props.selectedCustomers[0] != null;

  checkTravelerDataLoading = () =>
    this.props.isTravelerInformationLoading || this.props.isFetchingVisaAdvisory;

  checkoutProgressAttributes = () => {
    const attributes = {
      cartId: this.props.cartId,
      currentStep: 'traveler_details',
      cartError: !this.props.bookingPossible,
      detailsError: this.haveError(),
      nextButton: this.renderProceedToPaymentButton(),
      nextStepButtonHints: this.hintsForStepperButton(),
      nextButtonDisabled:
        !this.props.bookingPossible || this.haveError() || this.checkTravelerDataLoading(),
      nextStepButtonAction: this.saveTravelersOnClick,
      nextStepButtonHasAction: true,
      totalCartItems: this.props.totalCartItems,
    };
    if (this.approvalRequired()) {
      attributes.nextButton = (
        <RequestApproval
          sendApprovalRequest={this.sendApprovalRequest}
          disabled={this.state.approvalButtonDisabled || !this.travellerSelected()}
          cartId={this.props.cartId}
        />
      );
    }

    return attributes;
  };

  renderProceedToPaymentButton = () => {
    if (this.props.travelersError.status) {
      return (
        <Tooltip
          className="progress-spinner"
          content={<I18nText id="traveler_details.pending_message" returnStringOnly={true} />}
          gap={2}
          type="inverse"
          showArrow={false}
          position="bottom-right"
        >
          <div className="progress-spinner__circular">
            <Spinner size="tiny" bgColor="neutral" />
          </div>
        </Tooltip>
      );
    }

    if (
      this.context.travelerLoggedIn &&
      !this.props.selectedCustomers[0] &&
      !this.props.fields.travelers[0].guestUser.value
    ) {
      return (
        <Tooltip
          content={<I18nText id="traveler_details.loading_message" returnStringOnly={true} />}
          gap={2}
          type="inverse"
          showArrow={false}
        >
          <div className="progress-spinner__circular">
            <Spinner size="tiny" bgColor="neutral" />
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  render() {
    const flightPresent =
      typeof this.props.flightResults !== 'undefined' && this.props.flightResults.length > 0;
    const disabled =
      this.context.travelArranger && !this.context.travelArranger.customerInformationEditable;
    const visible =
      !this.context.travelArranger || this.context.travelArranger.customerInformationVisible;
    const editable =
      this.context.travelArranger &&
      this.context.travelArranger.customerInformationEditable &&
      this.context.travelArranger.customerInformationVisible;

    const sortedTravelerError = this.props.travelersError.errorList.sort(
      (a, b) => a.travelerIndex - b.travelerIndex,
    );

    const isTravelerDataLoading = this.checkTravelerDataLoading();

    return (
      <form className="traveler-details">
        <CheckoutProgress {...this.checkoutProgressAttributes()} />
        <div className="container container--sm-full-width">
          {!this.props.travelersError.status &&
            this.props.travelersError.errorList.length > 0 &&
            sortedTravelerError.map(error => (
              <div className="grid traveler-show__grid">
                <div className="traveler-show traveler-show__error-alert col-12 col-bleed-y">
                  <Alert type="warning" isVisible={true}>
                    {I18n.t('components.ibe.traveler_details.error_alert', {
                      count: this.props.selectedCustomers.length,
                      errorMessage: error.errorMessage,
                      travelerIndex: error.travelerIndex + 1,
                    })}
                  </Alert>
                </div>
              </div>
            ))}
          {!this.props.bookingPossible && (
            <div className="grid traveler-show__grid">
              <div className="traveler-show traveler-show__error-alert col-12 col-bleed-y">
                <Alert type="warning" isVisible={true}>
                  <I18nText id="traveler_details.cart_incomplete" />
                </Alert>
              </div>
            </div>
          )}

          <div className="grid traveler-details__card-container">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <SelectTravelers
                selectedTravelerIndex={this.state.activeTravelerIndex}
                setActiveTraveler={this.setActiveTraveler}
                fields={this.props.fields.travelers}
                travelerHasErrors={this.fieldsHasErrors}
                passEmailToAirline={this.props.passEmailToAirline}
                passPhoneToAirline={this.props.passPhoneToAirline}
                setResoOptionEmail={this.props.setResoOptionEmail}
                setResoOptionPhone={this.props.setResoOptionPhone}
                flightPresent={flightPresent}
                isTravelerDataLoading={isTravelerDataLoading || this.props.isSavingTravelerDetails}
              />
            </div>
            <div className="col-lg-9 col-md-12 col-bleed-x col-sm-12 col-xs-12 col-xxs-12">
              <SingleTravelerInformations
                index={this.state.activeTravelerIndex}
                key={this.state.activeTravelerIndex}
                flightResults={this.props.flightResults}
                selectedCustomer={this.props.selectedCustomers[this.state.activeTravelerIndex]}
                fields={this.props.fields.travelers[this.state.activeTravelerIndex]}
                allTravelers={this.props.fields.travelers}
                templateTraveler={this.props.fields.travelers[0]}
                disabled={disabled}
                customerSelected={this.props.customerSelected}
                reset={this.resetTraveler}
                visible={visible}
                editable={editable}
                internationalFlight={this.props.internationalFlight}
                resetForGuest={this.props.resetForGuest}
                fetchCurrentCustomer={this.props.fetchCurrentCustomer}
                currentCustomer={this.props.currentCustomer}
                phoneNumberValidate={this.phoneNumberValidate}
                travelerEmailValidate={this.travelerEmailValidate}
                mealRequests={this.props.mealRequests}
                toggleDisabled={this.state.proceedToPaymentButtonClicked}
                disableComponent={this.state.proceedToPaymentButtonClicked}
                enableIdDocOptional={this.props.enableIdDocOptional}
                identificationDocumentsRequired={this.props.identificationDocumentsRequired}
                countryCodes={this.props.countryCodes}
                operatingCarriers={this.props.operatingCarriers}
                assistanceRequests={this.props.assistanceRequests}
                isCarAdded={this.props.isCarAdded}
                isTravelerInformationLoading={
                  this.props.isTravelerInformationLoading && !this.props.proceedToPaymentPageView
                }
                travelerUpdateAbilities={this.props.travelerUpdateAbilities}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

TravelerDetails.propTypes = {
  bookingPossible: PropTypes.bool.isRequired,
  saveTravelers: PropTypes.func.isRequired,
  cartId: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  formValues: PropTypes.shape().isRequired,
  selectedCustomers: PropTypes.shape().isRequired,
  customerSelected: PropTypes.func.isRequired,
  setResoOptionEmail: PropTypes.func.isRequired,
  setResoOptionPhone: PropTypes.func.isRequired,
  internationalFlight: PropTypes.bool.isRequired,
  passEmailToAirline: PropTypes.bool.isRequired,
  passPhoneToAirline: PropTypes.bool.isRequired,
  fetchCurrentCustomer: PropTypes.func.isRequired,
  resetForGuest: PropTypes.func.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  initializeForm: PropTypes.func.isRequired,
  fetchCartPeople: PropTypes.func.isRequired,
  flightResults: PropTypes.arrayOf(
    PropTypes.shape({
      trips: PropTypes.arrayOf(
        PropTypes.shape({
          segments: PropTypes.arrayOf(
            PropTypes.shape({
              operatingCarrierCode: PropTypes.string.isRequired,
              operatingFlightNumber: PropTypes.string.isRequired,
            }).isRequired,
          ),
        }).isRequired,
      ),
    }),
  ),
  currentCustomer: PropTypes.shape(),
  asyncPhoneNumberValidate: PropTypes.func.isRequired,
  asyncTravelerEmailValidate: PropTypes.func.isRequired,
  resetTravelersError: PropTypes.func.isRequired,
  updateTravelerSavingStatus: PropTypes.func.isRequired,
  proceedToPayment: PropTypes.func.isRequired,
  travelersError: PropTypes.shape({
    status: PropTypes.bool,
    errorList: PropTypes.arrayOf(
      PropTypes.shape({
        errorMessage: PropTypes.string.isRequired,
        travelerIndex: PropTypes.number.isRequired,
      }),
    ),
  }),
  mealRequests: PropTypes.shape({
    availableCodes: PropTypes.arrayOf(PropTypes.string),
    supported: PropTypes.bool,
  }).isRequired,
  assistanceRequests: PropTypes.shape().isRequired,
  resetTraveler: PropTypes.func.isRequired,
  forceApprovalRequest: PropTypes.func.isRequired,
  saveApprovalRequest: PropTypes.func.isRequired,
  enableIdDocOptional: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool,
  fetchCart: PropTypes.func.isRequired,
  identificationDocumentsRequired: PropTypes.bool.isRequired,
  countryCodes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  operatingCarriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCarAdded: PropTypes.bool.isRequired,
  totalCartItems: PropTypes.number,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
  isFetchingVisaAdvisory: PropTypes.bool.isRequired,
  isSavingTravelerDetails: PropTypes.bool.isRequired,
  updateProceedToPaymentPageView: PropTypes.bool.isRequired,
  proceedToPaymentPageView: PropTypes.bool.isRequired,
  travelerUpdateAbilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      currentUseHasUpdateAbility: PropTypes.bool,
    }),
  ).isRequired,
};

TravelerDetails.defaultProps = {
  flightResults: [],
  currentCustomer: {},
  travelersError: {
    status: false,
    errorList: [],
  },
  laymanMode: false,
  totalCartItems: 0,
};

TravelerDetails.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  travelArranger: PropTypes.shape({
    customerInformationVisible: PropTypes.bool.isRequired,
    customerInformationEditable: PropTypes.bool.isRequired,
  }).isRequired,
  travelArrangerDefaultEmail: PropTypes.string.isRequired,
};

export default hot(module)(TravelerDetails);
