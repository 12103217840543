import React, { useState } from 'react';
import Input from '@wtag/rcl-input';
import { Link } from '@wtag/react-comp-lib';
import Alert from 'sharedWebpack/Alert';
import Button from '@wtag/rcl-button';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';

const LogInForm = ({ newPasswordPath }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');

  const logInUser = async () => {
    const logInParams = {
      email,
      password,
    };

    const { data } = await httpClient.post(routes.public.authentication.sessions.create(), {
      session: logInParams,
    });

    if (data.errors === null) window.location.reload();
  };

  const submitForm = event => {
    event.preventDefault();
    logInUser().catch(({ response }) => {
      setError('');
      if (response.status === 422) {
        setError(response.data.errors);
      }
    });
  };

  const closeError = () => setError('');

  return (
    <form onSubmit={submitForm}>
      <div className="authentication">
        <div className="authentication-form grid">
          <div className="col-12">
            <Alert
              className="authentication-form__alert"
              hideClose={false}
              isVisible={error}
              onClose={closeError}
              type="danger"
            >
              {error}
            </Alert>
            <div className="authentication-form__title">
              {I18n.t('public.components.authentication.sessions.new.title')}
            </div>
            <div className="authentication-form__description--with-bottom-padding">
              {I18n.t('public.components.authentication.sessions.new.description')}
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                type="email"
                size="small"
                placeholder={I18n.t('public.components.authentication.sessions.placeholders.email')}
                label={I18n.t('public.components.authentication.sessions.labels.email')}
                onChange={value => setEmail(value)}
                value={email}
              />
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                type="password"
                size="small"
                placeholder={I18n.t(
                  'public.components.authentication.sessions.placeholders.password',
                )}
                label={I18n.t('public.components.authentication.sessions.labels.password')}
                onChange={value => setPassword(value)}
                value={password}
              />
            </div>
            <div className="col-12 col-bleed col-grid direction-row authentication-form__button--with-top-padding">
              <Button
                version="v2"
                size="normal"
                submitType="submit"
                primary={true}
                label={I18n.t('public.components.authentication.sessions.form.sign_in')}
                onClick={submitForm}
                fullWidth={true}
              />
            </div>
            <div className="col-12 col-bleed col-grid direction-row authentication-form__link authentication-form__link--with-top-padding">
              <Link href={newPasswordPath} modifier="default">
                {I18n.t('public.components.authentication.sessions.form.forgot_password')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

LogInForm.propTypes = {
  newPasswordPath: PropTypes.string.isRequired,
};

export default LogInForm;
